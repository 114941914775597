import axiosIns from '@/libs/axiosIns'

export default {
  namespaced: true,
  state: {
    permissions: [],
    role: '',
    taxonomies: {
      callLegitimate: [],
      conversationStatus: [],
      demandReason: [],
      demandType: [],
      deviceType: [],
      gender: [],
      howThePersonGotToKnowUs: [],
      messageDirection: [],
      messageStatus: [],
      messageType: [],
      notificationStatus: [],
      senderType: [],
      situationOfAccommodation: [],
      ageRange: [],
    },
  },
  getters: {
    getTaxonomies: state => state.taxonomies,
  },
  mutations: {
    setInitialData(state, data) {
      state.taxonomies = data.taxonomies
      state.permissions = data.permissions
      state.role = data.role
    },
  },
  actions: {
    fetchInitialData({ commit }) {
      return new Promise((resolve, reject) => {
        axiosIns.get('Configuration/InitialData')
          .then(response => {
            commit('setInitialData', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
