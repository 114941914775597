import axiosIns from '@/libs/axiosIns'

export default {
    namespaced: true,
    state: {
      token: null,
      userId: null,
    },
    mutations: {
      setToken(state, token) {
        state.token = token
      },
      setUserId(state, userId) {
        state.userId = userId
      },
      clearAuthData(state) {
        state.token = null
        state.userId = null
        localStorage.removeItem('token') // Optional: Clear token from localStorage
      },
    },
    getters: {
      getUserId: state => state.userId,
      getToken: state => state.token,
    },
    actions: {
      logout({ state, commit }, router) {
        return new Promise(resolve => {
          axiosIns.post('Auth/LogOut', {})
            .then(response => {
              // Clear auth data and resolve response
              commit('clearAuthData', state)
              resolve(response)
            })
            .catch(error => {
              // Handle the error if needed, or log it
              console.error('Logout failed:', error)
            })
            .finally(() => {
              // Always redirect to the login page and clear auth data
              commit('clearAuthData', state)
              router.push({ name: 'login' })
            })
        })
      },
      register({ commit }, data) {
        return new Promise((resolve, reject) => {
          axiosIns.post('Auth/Register', data)
            .then(response => {
              commit('users/addUserMutation', response.data, { root: true })
              resolve(response)
            })
          .catch(error => reject(error))
        })
      },
    },
  }
